import axios from "axios";
import baseUrl from "../utils/baseUrl";

const getHotels = async () => {
  const url = `${baseUrl}/api/ofertas/destacados`;
  const resp = await axios.get(url,
  {headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token"),
              'Content-Type': 'Aplication/json'}})
  return resp;
}

export {
  getHotels
}