import React from "react";
import { NavLink } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const images = [
  {
    id: 1,
    url: "images/nights/Banner-Consumo-De-Noches.jpg",
    alt: "Consumo de noches",
    link: "",
  },
];

const Banners = () => {
  const handleOnDragStart = (e) => e.preventDefault();
  const items = images.map(({ id, url, alt, link }) => (
    <div className="item" key={id}>
      <NavLink to={link ? link : ""}>
        <img
          src={url}
          alt={alt}
          className="img-fluid"
          onDragStart={handleOnDragStart}
          style={{ width: "100%" }}
        />
      </NavLink>
    </div>
  ));
  return (
    <>
      <AliceCarousel
        items={items}
        autoPlay
        autoPlayInterval={3000}
        fadeOutAnimation
        mouseTrackingEnabled
        buttonsDisabled={true}
        dotsDisabled={false}
        mouseTracking
        controlsStrategy="alternate"
        animationType="fadeout"
        animationDuration={800}
        disableButtonsControls
        infinite
      />
    </>
  );
};

export default Banners;
