import React, { useEffect, useRef } from "react";
import "../../styles/nights.css";

import { ReactComponent as Icon } from "../../assets/icons/arrow.svg";

const SliderComponent = ({ oferta }) => {
  const imageListRef = useRef(null);
  const prevSlideButtonRef = useRef(null);
  const nextSlideButtonRef = useRef(null);
  const scrollbarThumbRef = useRef(null);
  const sliderScrollbarRef = useRef(null);

  useEffect(() => {
    const imageList = imageListRef.current;
    const slideButtons = [
      prevSlideButtonRef.current,
      nextSlideButtonRef.current,
    ];
    const sliderScrollbar = sliderScrollbarRef.current;
    const scrollbarThumb = scrollbarThumbRef.current;
    const maxScrollLeft = imageList.scrollWidth - imageList.clientWidth;

    const handleMouseMove =
      (startX, thumbPosition, maxThumbPosition) => (e) => {
        const deltaX = e.clientX - startX;
        const newThumbPosition = thumbPosition + deltaX;
        const boundedPosition = Math.max(
          0,
          Math.min(maxThumbPosition, newThumbPosition)
        );
        const scrollPosition =
          (boundedPosition / maxThumbPosition) * maxScrollLeft;

        scrollbarThumb.style.left = `${boundedPosition}px`;
        imageList.scrollLeft = scrollPosition;
      };

    const handleMouseUp = (handleMouseMove) => () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp(handleMouseMove));
    };

    scrollbarThumb.addEventListener("mousedown", (e) => {
      const startX = e.clientX;
      const thumbPosition = scrollbarThumb.offsetLeft;
      const maxThumbPosition =
        sliderScrollbar.getBoundingClientRect().width -
        scrollbarThumb.offsetWidth;
      const moveHandler = handleMouseMove(
        startX,
        thumbPosition,
        maxThumbPosition
      );

      document.addEventListener("mousemove", moveHandler);
      document.addEventListener("mouseup", handleMouseUp(moveHandler));
    });

    slideButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const direction = button.id === "prev-slide" ? -1 : 1;
        const scrollAmount = imageList.clientWidth * direction;
        imageList.scrollBy({ left: scrollAmount, behavior: "smooth" });
      });
    });

    const handleSlideButtons = () => {
      slideButtons[0].style.display =
        imageList.scrollLeft <= 0 ? "none" : "flex";
      slideButtons[1].style.display =
        imageList.scrollLeft >= maxScrollLeft ? "none" : "flex";
    };

    const updateScrollThumbPosition = () => {
      const scrollPosition = imageList.scrollLeft;
      const thumbPosition =
        (scrollPosition / maxScrollLeft) *
        (sliderScrollbar.clientWidth - scrollbarThumb.offsetWidth);
      scrollbarThumb.style.left = `${thumbPosition}px`;
    };

    const handleScroll = () => {
      updateScrollThumbPosition();
      handleSlideButtons();
    };

    imageList.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      handleScroll();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
      imageList.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="gallery-container">
      <div className="slider-wrapper">
        <button
          ref={prevSlideButtonRef}
          id="prev-slide"
          className="slide-button"
          style={{
            transform: "rotate(180deg)",
          }}
        >
          <Icon />
        </button>
        <ul ref={imageListRef} className="image-list">
          {[...Array(5).keys()].map((index) => (
            <li key={index}>
              <img
                className="image-item"
                src={oferta["img" + (index + 1)]}
                alt="img-1"
              />
            </li>
          ))}
        </ul>
        <button
          ref={nextSlideButtonRef}
          id="next-slide"
          className="slide-button"
        >
          <Icon />
        </button>
      </div>
      <div ref={sliderScrollbarRef} className="slider-scrollbar">
        <div className="scrollbar-track">
          <div ref={scrollbarThumbRef} className="scrollbar-thumb"></div>
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
