import React, { useEffect, useState } from "react";
import { getInfo, servicePostChangePassword } from "../../service/getInfo";
import toast, { Toaster } from "react-hot-toast";
import { ReactComponent as IconEye } from "../../assets/icons/eye.svg";
import { ReactComponent as IconEyeOff } from "../../assets/icons/eyeOff.svg";
import "../../styles/components/ChangePassword.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useModalGac } from "../../common/hook/useModalGac";
import CustomModal from "../../common/modal/CustomModal";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [isOpenModal] = useModalGac(true);

  const location = useLocation();
  const [isLocked, setIsLocked] = useState(false);
  const [userInfo, setUserInfo] = useState({
    contrato: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });

  // Prevenir navegación cuando está bloqueado
  useEffect(() => {
    const preventNavigation = (e) => {
      if (isLocked) {
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    };

    // Prevenir recarga de página
    window.addEventListener("beforeunload", preventNavigation);

    // Prevenir navegación mediante el botón de retroceso
    window.addEventListener("popstate", (e) => {
      if (isLocked) {
        e.preventDefault();
        window.history.pushState(null, "", location.pathname);
      }
    });

    // Bloquear navegación hacia adelante
    window.history.pushState(null, "", location.pathname);

    return () => {
      window.removeEventListener("beforeunload", preventNavigation);
      window.removeEventListener("popstate", preventNavigation);
    };
  }, [isLocked, location.pathname]);

  useEffect(() => {
    const getServiceInfoUser = async () => {
      try {
        const response = await getInfo();
        setUserInfo((prevState) => ({
          ...prevState,
          contrato: response.data.data.info.contracto.contrato,
          password: response.data.data.info.afiliado1.cedula,
        }));
      } catch (error) {
        toast.error("Error al obtener información del usuario");
      }
    };
    getServiceInfoUser();
  }, []);

  const validatePasswords = () => {
    if (userInfo.newPassword !== userInfo.confirmNewPassword) {
      toast.error("Las contraseñas no coinciden.");
      return false;
    }
    if (userInfo.newPassword.length < 6) {
      toast.error("La contraseña debe tener al menos 6 caracteres.");
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogout = () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error durante el logout:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validatePasswords()) {
      const payload = {
        contrato: userInfo.contrato,
        password: userInfo.password,
        newpassword: userInfo.newPassword,
      };
      try {
        setIsLocked(true); // Bloquear la página
        await servicePostChangePassword(payload);
        toast.success("La contraseña se ha cambiado correctamente.");

        setTimeout(() => {
          handleLogout();
        }, 3000);
      } catch (error) {
        console.error("Error al cambiar la contraseña:", error);
        toast.error("Ha ocurrido un error, intente de nuevo");
        setIsLocked(false); // Desbloquear en caso de error
      }
    }
  };

  const togglePasswordVisibility = (field) => {
    setUserInfo((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <CustomModal isOpen={isOpenModal}>
      <section className="relative">
        {isLocked && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <p className="text-center text-lg">
                Procesando cambio de contraseña...
                <br />
                Por favor, no cierre ni recargue la página
              </p>
            </div>
          </div>
        )}
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 1500,
          }}
        />
        <form className="card-change-password" onSubmit={handleSubmit}>
          <img
            src="/images/AlianzaPriority2.webp"
            alt="Grupoa Alianza Colombia"
            className="img-logo-gac"
          />
          <p className="msj-alert">
            Es necesario realizar el cambio de contraseña
          </p>
          <div className="inputGac">
            <input
              type={userInfo.showPassword ? "text" : "password"}
              placeholder="Digite su contraseña"
              name="newPassword"
              value={userInfo.newPassword}
              onChange={handleChange}
              required
              minLength={6}
              disabled={isLocked}
            />
            <label htmlFor="newPassword">Nueva Contraseña</label>
            {userInfo.showPassword ? (
              <IconEye
                className="icon_password"
                onClick={() =>
                  !isLocked && togglePasswordVisibility("showPassword")
                }
              />
            ) : (
              <IconEyeOff
                className="icon_password"
                onClick={() =>
                  !isLocked && togglePasswordVisibility("showPassword")
                }
              />
            )}
          </div>
          <div className="inputGac">
            <input
              type={userInfo.showConfirmPassword ? "text" : "password"}
              placeholder="Digite su contraseña"
              name="confirmNewPassword"
              value={userInfo.confirmNewPassword}
              onChange={handleChange}
              required
              minLength={6}
              disabled={isLocked}
            />
            <label htmlFor="confirmNewPassword">Confirmar Contraseña</label>
            {userInfo.showConfirmPassword ? (
              <IconEye
                className="icon_password"
                onClick={() =>
                  !isLocked && togglePasswordVisibility("showConfirmPassword")
                }
              />
            ) : (
              <IconEyeOff
                className="icon_password"
                onClick={() =>
                  !isLocked && togglePasswordVisibility("showConfirmPassword")
                }
              />
            )}
          </div>

          <button className="btn-change-password" disabled={isLocked}>
            Guardar cambios
          </button>
        </form>
      </section>
    </CustomModal>
  );
};

export default ChangePassword;
