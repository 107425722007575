import React from "react";

const SelectPaymentGateway = ({ form_pay, setform_pay, setGoSteps }) => {
  const select_pay = (type) => {
    setform_pay({
      ...form_pay,
      pasarela: type,
    });
    setGoSteps(3);
  };

  return (
    <div className="container">
      <div className="mt-5 centrar">
        {form_pay.url_payu !== null ? (
          <a
            href={form_pay.url_payu}
            target="_blank"
            rel="noopener noreferrer"
            className="card_payment_payu"
          >
            <img src="/images/pasarela/payU.png" alt="pago payu priority" />
          </a>
        ) : (
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 centrar">
              <button
                className="card_payment_pse m-1"
                onClick={() => select_pay("mercado_pago")}
              >
                <img
                  style={{ margin: "0", height: "70px" }}
                  src="/images/pasarela/pse.png"
                  alt="PSE pagos grupo alianza colombia"
                />
                <p>Pagos seguros en línea</p>
              </button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 centrar">
              <button
                className="card_payment_mercado_pago m-1"
                onClick={() => select_pay("mercado_pago")}
              >
                <img
                  src="/images/pasarela/logo-mercado-pago.svg"
                  alt="mercado pago pagos grupo alianza colombia"
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectPaymentGateway;
