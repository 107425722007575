import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Index from "../layout/Index";

const Support = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname); //Google Analytics
    ReactGA.event({
      category: "Pagina de soporte",
      action: "ingreso pagina de soporte",
      label: "Notificación pagina de soporte",
    });
  }, []);

  return (
    <Index>
      <div className="container">
        <div className="card-afiliado">
          <div className="img-avatar"></div>
          <div className="card-afiliado-text">
            <div className="portada"></div>
            <div className="title-total">
              <div className="text-center">
                <h4> Lineas de contacto en Bogotá Colombia </h4>
              </div>
              <div className="desc">
                <p>
                  {" "}
                  Apreciado cliente, si presenta alguna duda o inquietud,
                  comuníquese a nuestras líneas de atención de lunes a viernes
                  de 8:00 am a 6:00 pm y sábados de 9:00 am a 3:00 pm.
                </p>
                <p>
                  lineas:
                  <a className="text-dark" href="tel:+576015520100">
                    {" "}
                    (601)5520100
                  </a>{" "}
                  -
                </p>

                <p>
                  <a
                    className="text-dark"
                    href="mailto:sac@grupoalianzacolombia.com"
                  >
                    {" "}
                    sac@grupoalianzacolombia.com{" "}
                  </a>
                  <a
                    className="text-dark"
                    href="mailto:reservas@grupoalianzacolombia.com"
                  >
                    {" "}
                    reservas@grupoalianzacolombia.com
                  </a>
                </p>
              </div>
              <div className="actions"></div>
            </div>
          </div>
        </div>
      </div>
    </Index>
  );
};

export default Support;
