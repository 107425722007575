import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const AuthWrapper = () => {
  const isExpired = (localStorage.getItem('token'))
  return (
    <>
      {
        isExpired 
        ? <Outlet />
        : <Navigate to="/" replace />
      }
    </>  
  )
}

export default AuthWrapper