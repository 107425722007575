import axios from "axios";
import baseUrl from "../utils/baseUrl";

const RequestPayu = async ( form_pay ) =>{
  const url = `${baseUrl}/api/payu/pagos`;
  const resp = await axios.post(url, form_pay,
    { headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }}
  )
  return resp;
}

export {RequestPayu}