import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { Toaster, toast } from 'react-hot-toast';
import '../styles/form-change-pass.css';
import baseUrl from '../utils/baseUrl';
import axios from 'axios';
const RequestPassword = () => {

  const [info, setInfo] = useState({
    cedula: ''
  })
  const [loading, setLoading] = useState(false)

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const url = `${baseUrl}/api/users/set-password`;
			const response = await axios.post(url, info, {
        header : 'Content-Type Aplication/json',
      });

      toast.success(response.data,{
        duration: 4500,
        position: 'top-center',
      });
      setInfo('')
      setTimeout(() => {
        window.location.href = "/";
      }, "2000")
    } catch (error) {
      toast.error('Revise los datos digitados')
    }finally{
      setLoading(false)
    }
  }
  return (
    <>
      <Navbar />
      <Toaster />
      <main className='centrar'>
        <form className="form_container mb-5" autoComplete="off" onSubmit={handleSubmit}>
          <div className="logo_container"></div>
          <div className="input_container">
            <label className="input_label">Número de cedula</label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              className="icon"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#141B34"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M18 11.004A3.645 3.645 0 0014.952 9.1 103.22 103.22 0 0010.329 9c-1.65 0-3.148.034-4.623.1-1.753.077-3.193 1.388-3.427 3.062C2.126 13.254 2 14.373 2 15.513c0 1.14.126 2.26.279 3.352.234 1.674 1.674 2.985 3.427 3.063.714.031 1.554.055 2.294.072"
              ></path>
              <path
                stroke="#141B34"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 9V6.5a4.5 4.5 0 019 0V9"
              ></path>
              <path
                fill="#141B34"
                d="M21.205 15.104l-.58.592.58-.591zm.215 1.373a.829.829 0 001.16-1.183l-1.16 1.183zm-3.397-1.373l-.58-.59.58.59zm.215 2.935a.828.828 0 101.16-1.183l-1.16 1.183zm-3.978 2.723a1.45 1.45 0 01-2.02 0l-1.161 1.182a3.108 3.108 0 004.342 0l-1.16-1.182zm-2.02 0a1.352 1.352 0 010-1.942l-1.161-1.182a3.01 3.01 0 000 4.306l1.16-1.182zm0-1.942a1.45 1.45 0 012.02 0l1.161-1.182a3.108 3.108 0 00-4.342 0l1.16 1.182zm2.02 0a1.353 1.353 0 010 1.942l1.161 1.182a3.01 3.01 0 000-4.306l-1.16 1.182zm6.364-3.124l.796.78 1.16-1.181-.795-.782-1.16 1.183zm-5.203 3.124l2.387-2.343-1.161-1.183-2.387 2.344 1.161 1.182zm2.387-2.343l.795-.781-1.16-1.183-.796.781 1.16 1.183zm-1.161 0l1.59 1.562 1.162-1.183-1.591-1.562-1.161 1.183zm5.138-1.964c-.358-.351-.685-.675-.986-.9-.32-.24-.703-.441-1.185-.441v1.656s.005 0 .026.008c.026.01.078.037.166.103.19.143.427.372.818.757l1.161-1.183zm-3.182 1.183c.392-.385.627-.614.819-.757a.809.809 0 01.166-.103c.02-.009.025-.008.026-.008v-1.656c-.483 0-.866.201-1.186.441-.3.225-.627.549-.986.9l1.161 1.183z"
              ></path>
            </svg>
            <input 
              placeholder="Cedula" 
              type="number" 
              className="input_field fInput" 
              autoComplete="off"  
              required
              name='cedula'
              value={info.cedula}
              onChange={ (e)=> setInfo({cedula: e.target.value})}
              min={0}
            />
          </div>
          <button title="Sign In" type="submit" className="sign-in_btn">
            {loading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {' '}Cargando...
              </>) : (
              <span>Enviar</span>
              )}
          </button>
        </form>
      </main>
    </>
  )
}

export default RequestPassword