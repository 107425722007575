import React, { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import DetailsPay from "../common/pay/DetailsPay";
import MakePayment from "../common/pay/MakePayment";
import Index from "../layout/Index";
import SelectPaymentGateway from "../common/pay/SelectPaymentGateway";
import InternationalPayments from "../common/pay/InternationalPayments";
import InfoMembership from "../components/user/InfoMembership";

const INITIAL_STATE = {
  step: 1,
  monto: "",
  nombre: "",
  correo: "",
  contrato: "",
  pasarela: "",
  codigo: "",
  url_payu: null,
};

const PlaceOrder = () => {
  const [goSteps, setGoSteps] = useState(0);
  const [form_pay, setform_pay] = useState(INITIAL_STATE);
  useEffect(() => {
    var obj = document.getElementById("body");
    obj.style.removeProperty("overflow");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setform_pay((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePreviousStep = () => {
    setform_pay({ ...form_pay, url_payu: null });
    setGoSteps((prevStep) => prevStep - 1);
  };
  const renderStepContent = () => {
    switch (goSteps) {
      case 0:
        return (
          <DetailsPay
            handleChange={handleChange}
            form_pay={form_pay}
            setform_pay={setform_pay}
            setGoSteps={setGoSteps}
          />
        );
      case 1:
        return (
          <InternationalPayments
            handleChange={handleChange}
            form_pay={form_pay}
            setform_pay={setform_pay}
            setGoSteps={setGoSteps}
          />
        );
      case 2:
        return (
          <SelectPaymentGateway
            setform_pay={setform_pay}
            form_pay={form_pay}
            setGoSteps={setGoSteps}
          />
        );
      case 3:
        return (
          <MakePayment
            setform_pay={setform_pay}
            form_pay={form_pay}
            setGoSteps={setGoSteps}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Index>
      <div className={"Box-pagos"}>
        <InfoMembership />
        <div className="container-pagos">
          <p className="Title-pagos">PAGOS</p>
          <Stepper
            activeStep={goSteps}
            styleConfig={{
              activeBgColor: "#003893",
              activeTextColor: "#fff",
              inactiveBgColor: "#e9e7e7",
              inactiveTextColor: "#003893",
              completedBgColor: "#ffcf04",
              completedTextColor: "#003893",
              size: "3em",
            }}
            className={"stepper stepper-custom"}
            stepClassName={"stepper__step"}
          >
            <Step
              onClick={() => setGoSteps(0)}
              label="Detalles de pago"
              className="Prueba"
            />
            <Step onClick={() => setGoSteps(1)} label="Tipo de pago" />
            <Step onClick={() => setGoSteps(2)} label="Pasarela de pago" />
            <Step onClick={() => setGoSteps(3)} label="Realizar pago" />
          </Stepper>
          {renderStepContent()}

          {goSteps > 0 && (
            <div className="btn-atras">
              <button onClick={handlePreviousStep}>
                <img src="/Button-Atras.svg" alt="Btn-Atras" />
              </button>
              <p>Atrás</p>
            </div>
          )}
        </div>
      </div>
    </Index>
  );
};

export default PlaceOrder;
