import React, { createContext, useEffect, useState } from 'react'

export const DataContext = createContext();

export const DataProvider = ( {children}) => {

  const [data, setData] = useState({
    token: '',
    active: true
  })

  useEffect(() => {
    setData({
      token: localStorage.getItem('token'),
      active: true
    })
  }, [])
    
  return (
    <DataContext.Provider value={{
      data,
      setData
    }}>
      {children}
    </DataContext.Provider>
  )
}