import React, { useEffect, useState } from "react";
import { getHotels } from "../service/getHotels";
import Index from "../layout/Index";
import FeaturedCoupons from "../components/cupons/FeaturedCoupons";
import Banners from "../components/Banners";
import Load from "../common/Load";
import { useNavigate } from "react-router-dom";

const Nights = () => {
  const [Hotelsnoches, setHotelsnoches] = useState(null);
  const [Hotels, setHotels] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("state_mora") === "true" ||
      localStorage.getItem("price") === ""
    )
      navigate(-1);
    async function fetchData() {
      try {
        const response = await getHotels();
        if (response.msg === "Token invalid") {
          localStorage.clear();
          return navigate("/");
        }
        const hotelsWithConvenios = response.data.filter(
          (hotel) => hotel.convenio === 1
        );
        const hotelsWithoutConvenios = response.data.filter(
          (hotel) => hotel.convenio === 0
        );
        setHotelsnoches(
          hotelsWithoutConvenios.sort((a, b) => new Date(a.id) - new Date(b.id))
        );
        setHotels(
          hotelsWithConvenios.sort((a, b) => new Date(a.id) - new Date(b.id))
        );
      } catch (error) {
        console.error(error);
      }
    }
    var obj = document.getElementById("body");
    obj.style.removeProperty("overflow");
    fetchData();
  }, []);

  return (
    <Index>
      <Banners />
      <div className="row">
        <h1 className="text-center title-alianza-plus mt-3 mb-3">
          Redime tus noches
        </h1>
        {Hotels ? <FeaturedCoupons cupon={Hotelsnoches} tipo={0} /> : <Load />}
      </div>
      <div className="row">
        <h1 className="text-center title-alianza-plus mt-3 mb-3">Convenios</h1>
        {Hotels ? <FeaturedCoupons cupon={Hotels} tipo={1} /> : <Load />}
      </div>
    </Index>
  );
};

export default Nights;
