import React, { useEffect, useState } from "react";
import { getInfo } from "../../service/getInfo";
import Load from "../../common/Load";

const InfoMembership = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getInfo();
        setUserData(response.data.data.info);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return userData ? (
    <div className="info-user">
      <h1
        style={{
          fontSize: "2em",
          fontWeight: "700",
          textTransform: "uppercase",
        }}
      >
        Mi cuenta
      </h1>
      <p>{userData?.afiliado1?.titular}</p>
      <p>{userData?.afiliado2?.titular}</p>
      <p>
        <strong>Membresía: </strong>
        {userData?.contracto?.membresia}
      </p>
      <p>
        <strong>Contrato: </strong>
        {userData?.contracto?.contrato}
      </p>
      <p>
        <strong>Afiliados: </strong> {userData?.contracto?.afiliados}
      </p>
      <p>
        <strong>Beneficiarios: </strong>
        {userData?.contracto?.beneficiarios}
      </p>
      <p>
        <strong>Noches por año: </strong>
        {userData?.contracto?.noches || 0}
      </p>
    </div>
  ) : (
    <Load />
  );
};

export default InfoMembership;
