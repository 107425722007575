import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Index from "../layout/Index";

const Alliance = () => {
  useEffect(() => {
    ReactGA.initialize("G-BJ1KD1C6WZ");

    ReactGA.pageview(window.location.pathname); //Google Analytics
    ReactGA.event({
      category: "Pagina de alianza plus",
      action: "Pagina de alianza plus",
      label: "Notificación Pagina de alianza plus",
    });
  }, []);

  return (
    <Index>
      <main className="container">
        <a
          href="https://alianzaplus.com/loginafiliado"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/alianza/AlianzaPlus.jpg"
            alt="Alianza plus"
            className="img-fluid mb-5 mt-4"
          />
        </a>
      </main>
    </Index>
  );
};

export default Alliance;
