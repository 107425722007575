import React from "react";
import Navbar from "../components/Navbar";
const Index = ({ children }) => {
  return (
    <>
      <Navbar />
      <main style={{ marginTop: "90px", minHeight: "100vh" }}>{children}</main>
    </>
  );
};

export default Index;
