import axios from "axios";
import baseUrl from "../utils/baseUrl";

const getPrice = async () => {
  const url = `${baseUrl}/api/users/getprice`;
  try {
    const response = await axios.get(url, {
      header : 'Content-Type Aplication/json',
      headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
    });
    return response.data
  } catch (error) {
    console.log({error});
  }
}

export {
  getPrice
}