import axios from "axios";
import baseUrl from "../utils/baseUrl";

const getInfo = async () => {
  const url = `${baseUrl}/api/users/info-user`;
  try {
    const response = await axios.get(url, {
      header: "Content-Type Aplication/json",
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

const servicePostChangePassword = async (payload) => {
  const url = `${baseUrl}/api/users/changepassw`;
  const response = await axios.post(url, payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
  return response;
};

export { getInfo, servicePostChangePassword };
