import React from "react";
import "./index.css";
import "./styles/main.css";
import Main from "./layout/main";
import { DataProvider } from "./context/DataContext";
import RouterApp from "./router/routes";
import ReactGA from "react-ga";
import { UserMoraProvider } from "./context/userMoraContext";
import { BrowserRouter } from "react-router-dom";

const TRACKING_ID = "G-BJ1KD1C6WZ";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <BrowserRouter>
      <UserMoraProvider>
        <DataProvider>
          <Main>
            <RouterApp />
          </Main>
        </DataProvider>
      </UserMoraProvider>
    </BrowserRouter>
  );
};

export default App;
