import React from "react";
import { NavLink } from "react-router-dom";
import { useModalGac } from "./hook/useModalGac";
import CustomModal from "./modal/CustomModal";

const UserMora = ({ text }) => {
  const [isOpenModal] = useModalGac(true);
  return (
    <CustomModal isOpen={isOpenModal}>
      <div className="card-mora">
        <h3 className="text-center">
          {" "}
          Tu plan vacacional presenta mora {text}
        </h3>
        <a
          target="_blank"
          className="centrar text-white m-3"
          href="https://wa.link/ar2rk4"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          <img
            src="/images/icono-de-whatsapp.png"
            alt="img_WhatsApp"
            style={{ width: "20px" }}
          />
          <p>Si deseas contactar tu agente de cuenta</p>
        </a>
        <div className="modal-footer centrar">
          <NavLink
            to="/pagos"
            className="col-sm-12 col-md-12 col-lg-5 col-xl-5 btn-payU"
            style={{ border: "none" }}
          >
            <span>
              <img
                src="/icons/card_pay.svg"
                alt="img mercado de pago"
                width={38}
                style={{ marginRight: "10px" }}
              />
              Realiza tú Pago
            </span>
          </NavLink>
        </div>
      </div>
    </CustomModal>
  );
};

export default UserMora;
