import React from 'react';
import { NavLink } from 'react-router-dom';
// import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// const responsive = {
//   0: { items: 1 },
//   568: { items: 2 },
//   890: { items: 3 },
//   1024: { items: 4 },
// };

const FeaturedCoupons = ({ cupon, tipo }) => {

  // const items = [
  //   cupon?.map((cupon, index) => (
  //     <div className='item' data-value={index + 1} key={cupon.id}>
  //       <NavLink to={`/oferta/${cupon.id}`}>
  //         <div className="cardCupon cardOfertas">
  //           <div className='row'>
  //             <div className='col-lg-12 col-md-12 col-sm-12'>
  //               <div className="container-flag">
  //                 <div className="card_box">
  //                   <span></span>
  //                 </div>
  //               </div>
  //               {/* <div className="example2__ribbon example2__ribbon--tr">
  //                 <span className="example2__title" >Ver cupón</span>
  //               </div> */}
  //               <img src={cupon.portada} className="card-img-top" alt="..." />
  //             </div>
  //             <div className='col-lg-12 col-md-12 col-sm-12'>
  //               <div className="card-body ">
  //                 <p className="text-center">{cupon.titulo}</p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </NavLink>
  //     </div>

  //   ))
  // ];
  return (
    <div className='noches-hotels-grid'>
      {cupon?.map((cupon, index) => (
        <div className='item' data-value={index + 1} key={cupon.id}>
          <NavLink to={`/Solicitar/${cupon.id}/${cupon.titulo}`}>
            <div className="cardCupon cardOfertas">
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className="container-flag">
                    <div className={tipo === 0 ? "card_box" : "card_box1"}>
                      <span></span>
                    </div>
                  </div>
                  {/* <div className="example2__ribbon example2__ribbon--tr">
                  <span className="example2__title">Ver cupón</span>
                </div> */}
                  <div className='div-imagen-nights'>
                    <img src={cupon.portada} className="card-img-top" alt="..." />
                    <p className="image-title">{cupon.ciudad}</p>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className="card-body">
                    <p className="text-center nights-title">{cupon.titulo}</p>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      ))}
      { tipo === 1 ?
        <div className='item'>
          <a href={localStorage.getItem('price')} target="_blank" rel="noopener noreferrer" >
            <div className="cardCupon cardOfertas">
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className="container-flag">
                    <div className={"card_box1"}>
                      <span></span>
                    </div>
                  </div>
                  <div className='div-imagen-nights'>
                    <img src="https://images.pexels.com/photos/338504/pexels-photo-338504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
                    className="card-img-top" alt="..." width="377" height="142"/>
                    <p className="image-title">Destinos</p>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className="card-body">
                    <p className="text-center nights-title">Más hoteles y destinos</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div> : ""
      }
    </div>
    // <AliceCarousel
    //   mouseTracking
    //   controlsStrategy="alternate"
    //   autoPlay={true}
    //   autoPlayInterval={1500}
    //   animationType="fadeout" 
    //   animationDuration={800}
    //   disableButtonsControls
    //   items={ items[0] }
    //   infinite
    //   responsive={responsive}

    //   // onSlideChange={ (e) => console.log(e.item)}
    //   // onSlideChanged={ (e) => console.log(e.slide)}
    // />
  )
}

export default FeaturedCoupons