import React from "react";
import { useNavigate } from "react-router-dom";
import useTimeOut from "../common/hook/useTimeOut";
import Navbar from "../components/Navbar";

const InactiveUser = () => {
  const navigate = useNavigate();

  useTimeOut(() => redirect(), 15000);

  const redirect = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <Navbar />
      <div className="card-afiliado ">
        <div className="card-afiliado-text">
          <div className="portada"></div>
          <div className="pt-4 p-1">
            <h5 className="text-center">Tu membresía presenta una novedad </h5>
            <div className="desc">
              <p>
                Estimado cliente Agradecemos contactarse en Bogotá a nuestras
                líneas de atención de lunes a viernes de 8:00 am a 6:00 pm y
                sábados de 9:00 am a 3:00 pm.
              </p>
              <p>
                lineas:
                <a className="text-primary" href="tel:+576015520100">
                  {" "}
                  +57(601)5520100
                </a>
              </p>
              <p>
                <a
                  className="text-primary"
                  href="mailto:sac@grupoalianzacolombia.com"
                >
                  {" "}
                  sac@grupoalianzacolombia.com{" "}
                </a>
                <a
                  className="text-primary"
                  href="mailto:reservas@grupoalianzacolombia.com"
                >
                  {" "}
                  reservas@grupoalianzacolombia.com
                </a>
              </p>
              <p className="text-italic mt-4 text-center text-dark">
                Activa todos los beneficios que tenemos para ti
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveUser;
