import { createContext, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

const MoraContext = createContext();

export const useUserMora = () => useContext(MoraContext);

export const UserMoraProvider = ({ children }) => {
  const location = useLocation();

  const [isStateMora, setIsStateMora] = useState(() => {
    return localStorage.getItem("state_mora");
  });

  useEffect(() => {
    const userMora = localStorage.getItem("state_mora");
    setIsStateMora(userMora);
  }, [location]);

  return (
    <MoraContext.Provider value={{ isStateMora, setIsStateMora }}>
      {children}
    </MoraContext.Provider>
  );
};
