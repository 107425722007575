import React, { useEffect, useState } from "react";
import { getRequest } from "../service/getRequest";
import { downloadExcel } from "react-export-table-to-excel";
import "../styles/page-consult-nights.css";
export const Nightsconsult = () => {
  const [request, setrequest] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchDate, setSearchDate] = useState("");
  const header = [
    "id",
    "Contrato",
    "Hotel",
    "Fecha Salida",
    "Fecha Llegada",
    "Adultos",
    "Niños",
    "Edad niños",
    "Tipo",
    "Fecha Solicitud",
  ];
  const body = request;

  function handleDownloadExcel() {
    downloadExcel({
      fileName: `NochesConvenios ${new Date()}`,
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        body,
      },
    });
  }

  const requests = async () => {
    const peticion = await getRequest();
    const sortedRequests = peticion.sort(
      (a, b) => new Date(a.fecha_solicitud) - new Date(b.fecha_solicitud)
    );

    if (searchDate.length > 0) {
      const filteredRequests = sortedRequests.filter((solicitud) =>
        solicitud.fecha_solicitud.includes(searchDate)
      );
      setrequest(filteredRequests);
    } else {
      setrequest(sortedRequests);
    }
  };

  const itemsPerPage = 40;
  const totalPages = Math.ceil(request?.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = request?.slice(startIndex, endIndex);

  console.log(currentData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const peticion = await getRequest();
        const sortedRequests = peticion.sort(
          (a, b) => new Date(a.fecha_solicitud) - new Date(b.fecha_solicitud)
        );

        if (searchDate.length > 0) {
          const filteredRequests = sortedRequests.filter((solicitud) =>
            solicitud.fecha_solicitud.includes(searchDate)
          );
          setrequest(filteredRequests);
        } else {
          setrequest(sortedRequests);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // requests();
  }, [searchDate]);

  const handleClearSearch = async () => {
    setSearchDate(""); // Limpiar la fecha de búsqueda
    await requests(); // Volver a cargar todas las solicitudes
  };

  return (
    <>
      <div className="div-Nightsconsult mt-4">
        <h1 className="title-alianza-plus" style={{ textAlign: "center" }}>
          Solicitudes
        </h1>

        <section className="options">
          <div>
            <label htmlFor="searchDate">Buscar por fecha:</label>
            <input
              type="date"
              id="searchDate"
              value={searchDate}
              onChange={(e) => setSearchDate(e.target.value)}
            />
            {searchDate.length > 0 ? (
              <button className="btn-clear" onClick={handleClearSearch}>
                Limpiar búsqueda
              </button>
            ) : (
              ""
            )}
          </div>

          <button onClick={handleDownloadExcel} className="btn-excel">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-3.2 -3.2 38.4 38.4"
            >
              <path
                fill="#fff"
                strokeWidth="0"
                d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z"
                transform="matrix(2.4 0 0 2.4 -3.2 -3.2)"
              ></path>
              <g>
                <defs>
                  <linearGradient
                    id="a"
                    x1="4.494"
                    x2="13.832"
                    y1="-2092.086"
                    y2="-2075.914"
                    gradientTransform="translate(0 2100)"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#18884f"></stop>
                    <stop offset="0.5" stopColor="#117e43"></stop>
                    <stop offset="1" stopColor="#0b6631"></stop>
                  </linearGradient>
                </defs>
                <path
                  fill="#185c37"
                  d="M19.581 15.35L8.512 13.4v14.409A1.192 1.192 0 009.705 29h19.1A1.192 1.192 0 0030 27.809V22.5z"
                ></path>
                <path
                  fill="#21a366"
                  d="M19.581 3H9.705a1.192 1.192 0 00-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5z"
                ></path>
                <path fill="#107c41" d="M8.512 9.5h11.069V16H8.512z"></path>
                <path
                  d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 001.194-1.191V9.391A1.2 1.2 0 0016.434 8.2z"
                  style={{ isolation: "isolate" }}
                  opacity="0.1"
                ></path>
                <path
                  d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 001.194-1.191V10.041a1.2 1.2 0 00-1.194-1.191z"
                  style={{ isolation: "isolate" }}
                  opacity="0.2"
                ></path>
                <path
                  d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 001.194-1.191V10.041a1.2 1.2 0 00-1.194-1.191z"
                  style={{ isolation: "isolate" }}
                  opacity="0.2"
                ></path>
                <path
                  d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 001.194-1.191V10.041a1.2 1.2 0 00-1.194-1.191z"
                  style={{ isolation: "isolate" }}
                  opacity="0.2"
                ></path>
                <path
                  fill="url(#a)"
                  d="M3.194 8.85h11.938a1.193 1.193 0 011.194 1.191v11.918a1.193 1.193 0 01-1.194 1.191H3.194A1.192 1.192 0 012 21.959V10.041A1.192 1.192 0 013.194 8.85z"
                ></path>
                <path
                  fill="#fff"
                  d="M5.7 19.873l2.511-3.884-2.3-3.862h1.847L9.013 14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359 3.84 2.419 3.905h-1.809l-1.45-2.711A2.355 2.355 0 019.2 16.8h-.024a1.688 1.688 0 01-.168.351l-1.493 2.722z"
                ></path>
                <path
                  fill="#33c481"
                  d="M28.806 3h-9.225v6.5H30V4.191A1.192 1.192 0 0028.806 3z"
                ></path>
                <path fill="#107c41" d="M19.581 16H30v6.5H19.581z"></path>
              </g>
            </svg>
            Exportar en Excel
          </button>
        </section>

        {searchDate.length > 0 ? (
          <section className="result">
            <p className="d-flex info">
              <strong>Resultados de solictudes realizadas el: </strong>
              <p>{searchDate}</p>
            </p>
            <p>
              <strong>Resultados encontrados: {request?.length} </strong>
            </p>
          </section>
        ) : (
          ""
        )}

        <div className="div-tabla-scroll">
          {/* Opcion number 1 añadir al table ref={tableRef} */}
          <table
            className="mt-4 table table-bordered table-users-pers table-striped table-hover"
            border="1"
          >
            <thead className="">
              <tr>
                <th scope="col">Contrato</th>
                <th scope="col">Tipo</th>
                <th scope="col">fecha_solicitud</th>
                <th scope="col">Hotel</th>
                <th scope="col">fecha Salida</th>
                <th scope="col">Fecha Llegada</th>
                <th scope="col">Adultos</th>
                <th scope="col">Niños</th>
                <th scope="col">Edad niños</th>
              </tr>
            </thead>
            <tbody>
              {currentData?.map((solicitud) => (
                <tr key={solicitud.id}>
                  <td>{solicitud.contrato}</td>
                  <td>{solicitud.tipo}</td>
                  <td>{solicitud.fecha_solicitud}</td>
                  <td>{solicitud.hotel}</td>
                  <td>{solicitud.fecha_salida}</td>
                  <td>{solicitud.fecha_llegada}</td>
                  <td>{solicitud.adultos}</td>
                  <td>{solicitud.niños}</td>
                  <td>{solicitud.edad_niños}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li
              className={`page-item paginationbutton ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Anterior
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) =>
              i === currentPage - 2 ||
              i === currentPage - 1 ||
              i === currentPage ||
              i === currentPage + 1 ? (
                <li className="page-item">
                  <button
                    className={`page-link ${
                      currentPage === i + 1 ? "currentbutton" : ""
                    }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ) : null
            )}
            <li
              className={`page-item paginationbutton ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Siguiente
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
