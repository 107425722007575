import axios from "axios";
import baseUrl from "../utils/baseUrl";

export const getRequest = async () => {

    try {
      const url = `${baseUrl}/api/ofertas/ofertas-redimidas`;
      const resp = await axios.get(url)
      return resp.data;
  
    } catch (error) {
      return { err: error };
    }
  }