import React from 'react'

const Load = () => {
  return (
    <div className="centrar">
      <img src='/load_GAC.webp' alt='Grupo alianza colombia' style={{ width: '50%'}} />
    </div>
  )
}

export default Load